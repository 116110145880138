import { second2timestamp, timestamp2seconds } from "./timestamp"
import { v4 as uuid } from "uuid"
import { parse } from '@plussub/srt-vtt-parser';
import { API_BASE } from "./consts";
import axios from 'axios';
import { FileInput } from "../components/form";

export type Caption = {
  start: number
  end: number
  content: string
  hash: string // for rendering
}

export type Language = {
  key:string,
  value: string
}

export function captionsCompare(a: Caption, b: Caption): number {
  if (a.start > b.start) return 1
  else if (b.start > a.start) return -1
  else return 0
}

export function areSameCaptions(a: Caption, b: Caption): boolean {
  return (a.start === b.start) && (a.end === b.end) && (a.content === b.content)
}

// save as .srt format
export function export2srt(caps: Caption[]): string {
  return caps.map((c, i) =>
    `${i + 1}\n${second2timestamp(c.start, "complete")} --> ${second2timestamp(c.end, "complete")}\n${c.content}`)
    .join('\n\n')
}

export function parseSrt(content: string): Caption[] {
  const matches = Array.from(content.matchAll(
    /\d+\n([\d,:]{12}) --> ([\d,:]{12})\n(.+?)(?=\d+\n[\d,:]{12}|$)/gs))
  const { entries } = parse(content);
  // console.log(entries.map(m => ({
  //     start: m.from/1000,
  //     end: m.to/1000,
  //     content: m.text.trim(),
  //     hash: uuid()
  //   })))
  // console.log(matches.map(m => ({
  //   start: timestamp2seconds(m[1]),
  //   end: timestamp2seconds(m[2]),
  //   content: m[3].trim(),
  //   hash: uuid()
  // })))
  var captions = [] as Caption[]
  entries.forEach((m) => {
    if(m.text.trim().length>0) captions.push(({
      start: m.from/1000,
      end: m.to/1000,
      content: m.text.trim(),
      hash: uuid()
    }))
  });
  return captions;
}

export async function getLanguages(){
  try {
    const response = await axios.get(API_BASE+"system/getlanguages")
    return response.data.map((val: any): Language => ({
      key: val.key,
      value: val.value
    }));
  } catch (error) {
    console.error(error);
  }
}

export async function getSRTfromVideo(fileInput:FileInput,lang:string){
  var formData = new FormData();
  if(fileInput.fileInput.current?.files?.length) {
    formData.append('upload_file', fileInput.fileInput.current?.files[0]);
  }
  formData.append('sourcelanguage', lang);
  try {
    const response = await axios.post(API_BASE+"system/uploadmobile",formData,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return response.data.srt.map((val:any)=>({
      start:val.start,
      end:val.end,
      content:val.text,
      hash:uuid()
    }))
  } catch (error) {
    console.error(error);
  }
}