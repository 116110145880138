import React, { ChangeEvent, createRef, RefObject } from 'react'
import './file-input.sass'


class FileInput extends React.Component<{
	onChange?: (f: File) => void
	accept: string,
	btnText:string,
	iconClassName:string
}, {accept:string}> {

	fileInput: RefObject<HTMLInputElement>

	constructor(props: any) {
		super(props)

		this.fileInput = createRef()

		this.changeHandler = this.changeHandler.bind(this)
		this.redirectToInput = this.redirectToInput.bind(this)
	}

	changeHandler(e: ChangeEvent<HTMLInputElement>) {
		const inputFileElem = e.target

		// send selected file to it
		if (this.props.onChange && inputFileElem.files?.length) {
			const file = inputFileElem.files[0]
			this.props.onChange(file)
		}
	}

	redirectToInput() {
		this.fileInput.current?.click()
	}

	render() { 
		return (
			<div className="form-group file-input-wrapper">
				<input type="file" accept={this.props.accept} ref={this.fileInput} className="d-none"
					onChange={this.changeHandler} />

				<button title={this.props.btnText}
					className="btn circle-btn add-caption-btn"
					onClick={e => this.redirectToInput()}>
					<span className={this.props.iconClassName}> </span>
				</button>
				{/* <div className="badge text-body"><span> {this.props.btnText} </span></div> */}
			</div>
		)
	}
}

export default FileInput