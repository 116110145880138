import React from 'react'
import { Caption } from "../../utils/caption"
import './caption-view.sass'

type Props = {
  caption: Caption | null
}
export default class CaptionView extends React.Component<Props> {
  constructor(props: any) {
    super(props)
  }

  render() {
    const content = this.props.caption?.content || ""

    return (
      <div className="caption-group">
        {content === "" ? '' :
          <div className="caption">
            {content}
          </div>
        }
      </div>
    )
  }
}

