import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import { Intro, Studio, Help } from "./pages"
import { Toast } from "./components/form"

import "./global.sass"

export default class App extends React.Component {
  render() {
    return (<>
      <Studio/>
      <Toast />
    </>)
  }
}